import { Injectable } from '@angular/core';
import * as parse from 'parse';
import { Pilote } from '../../classes/pilote';
import { UserService } from '../user/user.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class PiloteService {

  code = '4XX';
  description = 'Erreur de reponse';
  reponse: any;

  constructor(private user: UserService,
    private utils: UtilsService, 
    private userSv : UserService
    ) { }

  async creation(data: Pilote, typeUtilisateur?) {

    const piloteParams: Pilote = {
      nom_complet: data.nom_complet,
      email: data.email,
      telephone: data.telephone,
      entreprise: null,
      user: data.user,
      supprime: false,
      type: data.type,
      responsable: null,
      active: data.active,
      definition : data.definition
    };

    const UserParams: any = {
      username: typeUtilisateur && typeUtilisateur + this.utils.generateCode(5).toLowerCase() || 'man_' + this.utils.generateCode(5).toLowerCase(),
      email: data.email,
      password: data.password,
      type_utilisateur: data.type
    };

    const ParsePilote = new parse.Object('Pilote');
    const responsable = new parse.Object('Pilote');
    
    responsable.id = data.responsable;

    const entreprise = new parse.Object('Entreprise');
    entreprise.id = data.entreprise;

    try {
      const reponseUser = await this.user.creation(UserParams);
      piloteParams.user = reponseUser;
      piloteParams.entreprise = entreprise;
      piloteParams.responsable = responsable

      const reponsePilote = await ParsePilote.save(piloteParams);

      return {
        code: 200,
        pilote: reponsePilote.toJSON(),
        user: reponseUser
      };
    } catch (error) {
      throw error;
    }

  }

  // modification d'un pilote
  async modification(pilote_id, data: Pilote) {
    let user : any = await this.user.recuperer()
    // fait get pour recuperer l'objet avec son id pour pouvoir faire upDate
    const ParsePilote = new parse.Object('Pilote');
    ParsePilote.id = pilote_id;

    const PiloteParams: Pilote = {
      nom_complet: data.nom_complet && data.nom_complet.toLowerCase(),
      email: data.email && data.email.toLowerCase(),
      telephone: data.telephone,
      active : data.active
    };

    try {
      const reponsePilote = await ParsePilote.save(PiloteParams);

      if (user.objectId == pilote_id) {
        await this.user.modification({
          email: data.email
        })
      }

      return {
        code: 200,
        pilote: reponsePilote.toJSON()
      };
    } catch (error) {
      throw error;
    }
  }

  suppression(objectId: string) {
    // fait get pour recuperer l'objet avec son id pour pouvoir faire upDate
    const Pilote = parse.Object.extend('Pilote');
    const query = new parse.Query(Pilote);
    // here you put the objectId that you want to update
    query.get(objectId).then(
      (object) => {
        object.set('supprime', true);
        return object.save().then(
          (response) => {
            this.reponse = 200;
            this.reponse = 'Supprimé';
            return { code: this.code, reponse: this.reponse };
          }
        );
      }
    );
  }

  async recuperation_plusieurs(id_entreprise) {
    const query = new parse.Query('Pilote');

    const entreprise = new parse.Object('Entreprise');
    entreprise.id = id_entreprise


    query.equalTo('entreprise', entreprise);
    query.include(['entreprise', "user"]);
    query.descending('createdAt')


    const results: Array<any> = await query.find(
      {
        sessionToken :  this.utils.sessionToken
      }
    );

    return results.map(result => result.toJSON()) as Array<Pilote>;
  }

  // recuperation d'un Pilote
  async recuperation_unique(userId?): Promise<Pilote | any> {

    let fUserId;

    if(!userId){
      let user : any = await this.userSv.recuperer()
      fUserId = user.objectId
    }else{
      fUserId = userId;
    }


    const query = new parse.Query('Pilote');
    const user = new parse.Object("_User");
    user.id = fUserId;

    query.equalTo('user', user);
    query.include('entreprise');

    const result = await query.first({
      sessionToken : this.utils.sessionToken
    });

    return {
      code: 200,
      ...(result && result.toJSON())
    };
  }

}
