import { Component } from '@angular/core';
import * as parse from 'parse';
import { environment } from 'src/environments/environment';
import { DateService } from './services/date/date.service';
import { UtilsService } from './services/utils/utils.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Gestionnaire';
  constructor(
    private timeSv: DateService,
    private utilSv : UtilsService
  ) {
    (parse as any).serverURL = environment.parse.serverUrl; // This is your Server URL
    parse.initialize(
      environment.parse.appId, // This is your Application ID
      environment.parse.jsKey // This is your Master key (never use it in the frontend)
    );
    this.timeSv.getTime()
    this.utilSv.setSessionToken()

  }
}
