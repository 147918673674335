import { Injectable } from '@angular/core';
import * as parse from 'parse';
import { BusinessAdviser } from '../../classes/business_adviser';
import { UserService } from '../user/user.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessAdviserService {

  constructor(
    private user: UserService,
    private utils: UtilsService
  ) { }

  // creation dun nouveau business adviser
  async creation(data: {
    nom_complet: string,
    email: string,
    telephone: string,
    niveau: number,
    mot_de_passe: string
  }) {

    const ParseBusinessAdviser = new parse.Object('BusinessAdviser');

    const BusinessAdviserParams: BusinessAdviser = {
      nom_complet: data.nom_complet,
      email: data.email,
      telephone: data.telephone,
      niveau: 1,
      user: null,
      active: false,
      supprime: false
    };

    const UserParms: any = {
      username: 'buad_' + this.utils.generateCode(5).toLowerCase(),
      email: data.email,
      password: data.mot_de_passe,
      type_utilisateur: 'business_adviser'
    };

    try {
      const reponseUser = await this.user.creation(UserParms);

      BusinessAdviserParams.user = reponseUser;
      const reponseBusinessAdviser = await ParseBusinessAdviser.save(BusinessAdviserParams);

      return {
        business_adviser: reponseBusinessAdviser.toJSON(),
        user: reponseUser.toJSON()
      };
    } catch (error) {

      throw error;

    }

  }

  // modification dun business adviser
  async modification(business_adviser_id, data: {
    nom_complet?: string,
    email?: string,
    telephone?: string,
    niveau?: number
    active?: boolean
    supprime?: boolean

  }) {
    // fait get pour recuperer l'objet avec son id pour pouvoir faire upDate
    const ParseBusinessAdviser = new parse.Object('BusinessAdviser');
    ParseBusinessAdviser.id = business_adviser_id;

    const BusinessAdviserParams: BusinessAdviser | any = {
      nom_complet: data.nom_complet,
      email: data.email,
      telephone: data.telephone,
      niveau: data.niveau,
      active: data.active
    };

    try {

      const reponseBusinessAdviser = await ParseBusinessAdviser.save(BusinessAdviserParams);

      return {
        code: 200, reponse: reponseBusinessAdviser.toJSON()
      };
    } catch (error) {
      throw error;
    }
  }

  // attribuer ou supprimer l'entreprise dun business adviser
  async modification_entreprises(action: 'ajout' | 'suppression', business_adviser_id, id_entreprise) {

    const ParseBusinessAdviser = new parse.Object('BusinessAdviser');
    ParseBusinessAdviser.id = business_adviser_id;

    const ParseEntreprise = new parse.Object('Entreprise');
    ParseEntreprise.id = id_entreprise;

    const entreprises_relation = ParseBusinessAdviser.relation('entreprises');

    if (action === 'ajout') {
      entreprises_relation.add(ParseEntreprise);
    } else if (action === 'suppression') {
      entreprises_relation.remove(ParseEntreprise);
    }

    try {

      const reponseBusinessAdviser = await ParseBusinessAdviser.save();

      return {
        code: 200, reponse: reponseBusinessAdviser.toJSON()
      };
    } catch (error) {

      throw error;

    }

  }

  // suppression dun business adviser
  async suppression(business_adviser_id: string) {
    // fait get pour recuperer l'objet avec son id pour pouvoir faire upDate
    const ParseBusinessAdviser = new parse.Object('BusinessAdviser');
    ParseBusinessAdviser.id = business_adviser_id;

    const BusinessAdviserParams: BusinessAdviser | any = {
      supprime: true
    };

    try {

      const reponseBusinessAdviser = await ParseBusinessAdviser.save(BusinessAdviserParams);

      return {
        ...reponseBusinessAdviser.toJSON()
      };
    } catch (error) {
      throw error;
    }
  }

  // recuperation d'un business adviser
  async recuperation_unique(user_id): Promise<BusinessAdviser> {
    const query = new parse.Query('BusinessAdviser');
    const user = new parse.User();
    user.id = user_id;

    query.equalTo('user', user);
    query.include('entreprises');
    const result = await query.first();

    let entreprises_relation: any
    let entreprises_relation_results: any

    if (!result)
      return null;

    if (result.get('entreprises')) {
      entreprises_relation = result.relation('entreprises');
      entreprises_relation_results = await entreprises_relation.query().equalTo('supprime', false).find();
    }

    return {
      ...result.toJSON(),
      entreprises: entreprises_relation_results.map(result => result.toJSON())
    } as any;
  }

  // recuperation d'un business adviser
  async recuperation_unique_par_id(object_id): Promise<BusinessAdviser> {
    const query = new parse.Query('BusinessAdviser');
    query.equalTo('objectId', object_id);

    query.include('entreprises');
    const result = await query.first();

    let entreprises_relation: any
    let entreprises_relation_results: any

    if (result && result.get('entreprises')) {
      entreprises_relation = result.relation('entreprises');
      entreprises_relation_results = await entreprises_relation.query().equalTo('supprime', false).find();
    }

    return {
      ...result.toJSON(),
      entreprises: entreprises_relation_results.map(result => result.toJSON())
    } as any;
  }


  // recuperation d'un business adviser
  /*async recuperation_unique(userId): Promise<Manager|any> {
    const query = new parse.Query('BusinessAdviser');
    const user =  new parse.User();
    user.id = userId;

    query.equalTo('user', user);
    query.include('entreprise');

    const result = await query.first();

    return {
      ...(result && result.toJSON())
    };
  }*/

  // recuperation de plusieurs business adviser
  async recuperation_plusieurs() {

    const query = new parse.Query('BusinessAdviser');
    query.include('entreprises');

    const results: Array<any> = await query.find();

    return results.map(result => result.toJSON()) as Array<BusinessAdviser | any>;
  }

  async filter(active, searchValue: string, niveau) {
    const query = new parse.Query('BusinessAdviser');
    query.include('entreprises');


    if (active != null) {
      query.equalTo("active", active)
    }

    if (niveau != null) {
      query.equalTo("niveau", niveau)
    }

    if (searchValue) {
      query.contains("nom_complet", searchValue.toLowerCase())
    }

    // query.equalTo('supprime', false);
    let results = await query.find()
    const reponse = []

    await this.utils.asyncForEach(results, async result => {
      let entreprises

      if (result.get('entreprises')) {
        const entreprisesR = await result.get('entreprises').query().equalTo('supprime', false).find()
        entreprises = entreprisesR.map(x => x.toJSON())
      }

      reponse.push({
        ...result.toJSON(),
        entreprises
      })
    })


    return reponse
  }

}
