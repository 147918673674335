import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { BusinessAdviserService } from '../services/business-adviser/business-adviser.service';
import { PiloteService } from '../services/pilote/pilote.service';
import { UserService } from '../services/user/user.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private business: BusinessAdviserService,
    private pilote: PiloteService,
    private user: UserService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise(async (res, rej) => {

      try {
        const user: any = await this.user.recuperer();

        if (user.type_utilisateur === 'superadmin') {
          res(true);
        } else if (
          user.type_utilisateur === 'manager' ||
          user.type_utilisateur === 'pilote' ||
          user.type_utilisateur === 'co-pilote' ||
          user.type_utilisateur === 'business_adviser'
        ) {

          let object: any;
          object = await this.pilote.recuperation_unique(user.objectId);

          res(false)
          return


        } else if (user.type_utilisateur === 'business_adviser') {
          let object: any;
          object = await this.business.recuperation_unique(user.objectId);

          const active = object.active ? true : false;

          if (active) {
            res(true);
          } else {
            res(false);
            location.href = '/confirmation';
          }
        } else {
          alert(user);
          res(false);
          location.href = '/accueil';

        }
      } catch (error) {
        alert("l'utilisateur a été déconnecté");
        res(false);
        location.href = '/accueil';
      }

    });
  }

}
