import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BusinessAdviserService } from '../services/business-adviser/business-adviser.service';
import { PiloteService } from '../services/pilote/pilote.service';
import { UserService } from '../services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AdviserAuthGuard implements CanActivate {

  constructor(
    private business: BusinessAdviserService,
    private pilote: PiloteService,
    private user: UserService,
    private router: Router
  ) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


    return new Promise(async (res, rej) => {

      try {
        const user: any = await this.user.recuperer();
        if (user.type_utilisateur === 'business_adviser') {

          let business_adviser: any;
          business_adviser = await this.business.recuperation_unique(user.objectId);
          const active = business_adviser.active

          if (active) {
            res(true);
          } else {
            res(false);
            location.href = '/confirmation';
          }

        }
      } catch (error) {
        alert(error);
        res(false);
        location.href = '/accueil';
      }

    });
  }

}
