import { Injectable } from '@angular/core';
import * as parse from 'parse';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public user = null;

  constructor() { }
  // code erreur
  /*
  125: "Email address format is invalid."
   */
  creation(params: any) {
    const user = new parse.User();
    const userObject = new parse.Object('_User');

    user.set(params);
    userObject.set(params);

    if (['superadmin', 'business_adviser', 'manager'].indexOf(params.type_utilisateur) != -1) {
      return user.signUp();
    } else {
      return userObject.save();
    }

  }

  async modification(data: {
    email?: string,
    password?: string
  }) {

    const ParseUser = new parse.Object('_User');
    ParseUser.set('email', data.email);
    const reponseUser = await ParseUser.save();

    return reponseUser;
  }

  async recuperer() {
    const result =  parse.User.current();
    if (result)
      return result.toJSON();

    return result
  }

  async initialiser() {
    this.user = await this.recuperer();
  }
}
