import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  public currentTime : number

  constructor(
  ) { }

  async getTime() {

    if (!this.currentTime) {
      let result: any // = await this.http.get("http://worldclockapi.com/api/json/utc/now").toPromise()
      if (result) {
        this.currentTime = new Date(result.currentDateTime).getTime()
        return this.currentTime
      } else return new Date().getTime()
    } else {
      return this.currentTime
    }
  }

}
