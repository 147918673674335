import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AdviserAuthGuard } from './guards/adviser-auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'accueil',
    pathMatch: 'full'
  },
  // {
  //   path: 'layout',
  //   component: LayoutComponent,
  //   loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule)

  // },
  {
    path: 'accueil',
    loadChildren: () => import('./pages/accueil/accueil.module').then(m => m.AccueilModule)
  },
  {
    path: 'connexion',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'inscription',
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupModule)

  },
  {
    path: 'confirmation',
    loadChildren: () => import('./pages/confirme/confirme.module').then(m => m.ConfirmeModule)
  },
  {
    path: 'entreprise',
   // canActivate: [EntrepriseAuthGuard],
    loadChildren: () => import('./pages/entreprise/entreprise.module').then(m => m.EntreprisesModule)
  },
  {
    path: 'business-adviser',
    canActivate: [AdviserAuthGuard],
    loadChildren: () => import('./pages/business/business.module').then(m => m.BusinessModule)
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule)
  },
  { path: '**', redirectTo: 'accueil' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
