import { Injectable } from '@angular/core';
import * as lodash from "lodash";
import * as parse from "parse"

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  sessionToken = ''

  constructor() { }

  generateCode(length: number) {
    let result = '';
    const characters = '0123456789AZERTYUIOPQSDFGHJKLMWXCVBN';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  getMonthDaysLength(y, m) {
    return new Date(y, m, 0).getDate()
  };

  async  asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  round(v) {
    return Math.round(v * 100) / 100
  }

  sumArray(array) {

    let results = array.reduce(function (r, a) {
      a.forEach(function (b, i) {
        r[i] = (r[i] || 0) + b;
      });
      return r;
    }, []);

    if(results.length === 0)
      return lodash.range(0,31,0)

    return results
  }

  setSessionToken(){
    const currentUser = parse.User.current()
    if(!this.sessionToken && currentUser)
      this.sessionToken = currentUser.getSessionToken()
  }
}
